import { ref, Ref } from 'vue';
import { CmsiApi } from '@/api';

import { toast } from '@/components/ui/Toast';
import { httpCode } from '@/common/constant';
import useLoading from '@/composables/loading';

import { CmSalesImpactGraphData } from '@/api/openapi';

interface Props {
  cmsiProductGroupId: number;
  companyId: number;
  startDate: string;
  endDate: string;
  reachWeeks: number;
  reachFrequency: number;
  areaCode: string;
  isCmsis: boolean;
}

interface ReferenceDuration {
  get: (Props) => void;
  data: Ref<CmSalesImpactGraphData>;
  minDate: Ref<Date>;
  maxDate: Ref<Date>;
  isLoading: Ref<boolean>;
}

export const useReferenceDuration = (): ReferenceDuration => {
  const data = ref({} as CmSalesImpactGraphData);
  const [isLoading, fetch] = useLoading(_fetch);
  const minDate = ref(new Date());
  const maxDate = ref(new Date());

  const get = async (props: Props) => {
    const res = await fetch(props);
    if (res) {
      data.value = res;
      minDate.value = new Date(
        data.value.weeklyPurchaseIncreaseGraph.map(v => v.weeklyDate)[0]
      );
      maxDate.value = new Date(
        data.value.weeklyPurchaseIncreaseGraph
          .map(v => v.weeklyDate)
          .slice(-1)[0]
      );
    }
  };

  return {
    data,
    minDate,
    maxDate,
    isLoading,
    get
  };
};

const _fetch = async (
  props: Props
): Promise<CmSalesImpactGraphData | false> => {
  const {
    cmsiProductGroupId,
    companyId,
    startDate,
    endDate,
    areaCode,
    reachWeeks,
    reachFrequency,
    isCmsis
  } = props;
  try {
    const { data } = await CmsiApi.getCmSalesImpactGraphData(
      cmsiProductGroupId,
      companyId,
      undefined,
      startDate,
      endDate,
      reachWeeks,
      reachFrequency,
      areaCode,
      isCmsis
    );
    return data;
  } catch (e) {
    if (e.state === httpCode.forbidden) {
      toast({
        title: '商品データ取得に失敗しました',
        message: e.message,
        variant: 'error'
      });
    }
    return false;
  }
};
