import { ref, Ref } from 'vue';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

import { CmsiApi } from '@/api';

import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

import { CmSalesImpactCorrelation } from '@/api/openapi';
import { httpCode } from '@/common/constant';

interface Props {
  cmsiProductGroupId: number;
  companyId: number;
  startDates: Array<Date>;
  endDates: Array<Date>;
  areaCode: string;
}
interface ReachBasicHeatmap {
  get: (Props) => void;
  data: Ref<HeatmapData>;
  isLoading: Ref<boolean>;
}

export const useReachBasicHeatmap = (): ReachBasicHeatmap => {
  const data = ref({} as HeatmapData);
  const [isLoading, fetch] = useLoading(_fetch);

  const get = async (props: Props) => {
    const res = await fetch(props);
    if (res) {
      data.value = compose(res);
    }
  };

  return {
    get,
    data,
    isLoading
  };
};

const compose = (res: CmSalesImpactCorrelation[]): HeatmapData => {
  const weeks = res.map(v => v.reachWeeks);
  const frequency = res.map(v => v.reachFrequency);

  const xAxis = Array.from(new Set(weeks)).map(v => v + '週間');
  const yAxis = Array.from(new Set(frequency)).map(v => v + '回');
  const series = res.map(v => ({
    x: v.reachWeeks - 1,
    y: v.reachFrequency - 1,
    value: v.correlationValue
  }));

  return {
    xAxis,
    yAxis,
    series
  };
};

const _fetch = async (
  props: Props
): Promise<CmSalesImpactCorrelation[] | false> => {
  const {
    cmsiProductGroupId,
    companyId,
    startDates,
    endDates,
    areaCode
  } = props;

  const formatter = e =>
    e instanceof Date ? format(e, 'yyyy-MM-dd', { locale: ja }) : e;
  const startDateList = startDates.map(formatter);
  const endDateList = endDates.map(formatter);

  try {
    const { data } = await CmsiApi.getCmSalesImpactHeatmap(
      cmsiProductGroupId,
      companyId,
      startDateList,
      endDateList,
      areaCode
    );
    return data;
  } catch (e) {
    if (e.status === httpCode.forbidden || e.name === 'RequiredError')
      return false;
    toast({
      title: 'リーチ基準ヒートマップの取得に失敗',
      variant: 'error'
    });
    return false;
  }
};
