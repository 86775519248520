import { ref, Ref, computed, watch, ComputedRef } from 'vue';
import { useRoute } from 'vue-router';

import { format, endOfWeek, startOfWeek, startOfDay } from 'date-fns';
import { roundNumber } from '@/common/formatter';
import {
  AreaInfoIdEnum,
  CmSalesImpactProductsDetails,
  CmSalesImpactReports,
  CmSalesImpactReportsForm,
  CmSalesImpactReportsPostResponse,
  CmSalesImpactAvailableArea,
  CmSalesImpactGraphData
} from '@/api/openapi';
import { SelectReport } from '@/composables/cmsi/editReport';

import { CmsiApi } from '@/api';

import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

import {
  SelectValueReachBasisWeek,
  SelectValueReachBasisFrequency,
  Area,
  Select,
  Save
} from '../types';

interface Title {
  reportTitleValue: Ref<string>;
  reportTitleValid: Ref<string>;
}

export interface AdvertisingCost {
  advertisingCostValue: Ref<string>;
  advertisingCostValid: Ref<string>;
}

interface CreateReport {
  isLoadingCreate: Ref<boolean>;
  onCreateReport: (FormValue) => Promise<CmSalesImpactReportsPostResponse>;
}

interface DateStart {
  startDateValue: Ref<Date>;
  startDateValid: ComputedRef<string>;
}
interface DateEnd {
  endDateValue: Ref<Date>;
  endDateBOfWValue: Ref<Date>;
  endDateBOfWValid: ComputedRef<string>;
}

interface FormValue {
  startDateValue: Ref<Date>;
  endDateValue: Ref<Date>;
  selectValueReachBasisWeek: Ref<Select>;
  selectValueReachBasisFrequency: Ref<Select>;
  areaValue: Ref<AreaInfoIdEnum>;
  advertisingCostValue: Ref<string>;
  reportTitleValue: Ref<string>;
}
interface CmsiReportCreate
  extends Area,
    DateStart,
    DateEnd,
    SelectValueReachBasisWeek,
    SelectValueReachBasisFrequency,
    Title,
    AdvertisingCost,
    CreateReport,
    Save {
  emptyDataValids: Ref<boolean>;
  isDisabled: Ref<boolean>;
  form: FormValue;
}

import * as Highcharts from 'highcharts';
import { httpCode } from '@/common/constant';

export interface PlotBands {
  color: string | Highcharts.PatternObject;
  from: number;
  to: number;
}

export const useCmsiReportCreate = ({
  report,
  product,
  mode,
  origGraphs
}: {
  report: SelectReport;
  product: CmSalesImpactProductsDetails;
  mode: string;
  origGraphs: CmSalesImpactGraphData;
}): CmsiReportCreate => {
  const route = useRoute();
  const { params } = route;
  const companyId = Number(params.companyId);
  const productId = Number(params.productId);
  const isDisabled = ref(false);

  // エリア
  const { areaList, areaValue, isLoadingArea } = useSimpleArea({
    companyId,
    productId,
    init: mode === 'create' ? product.areaCode : report.areaCode
  });
  // リーチ基準
  const {
    selectValueReachBasisWeek,
    optionsReachBasisWeek
  } = useSelectValueReachBasisWeek({
    init: mode === 'edit' ? report.reachWeeks : product.reachWeeks
  });
  const {
    selectValueReachBasisFrequency,
    optionsReachBasisFrequency
  } = useSelectValueReachBasisFrequency({
    init: mode === 'edit' ? report.reachFrequency : product.reachFrequency
  });

  // レポートの開始日
  const firstWeeklyDate =
    origGraphs.weeklyPurchaseIncreasePerPersonGraph[0].weeklyDate;
  // レポートの終了日
  const lastWeeklyDate = origGraphs.weeklyPurchaseIncreasePerPersonGraph.slice(
    -1
  )[0].weeklyDate;
  // 開始日
  const { startDateValue, startDateValid } = useDateStart({
    init:
      mode == 'edit'
        ? startOfWeek(new Date(report.startDate), { weekStartsOn: 1 }) //編集
        : new Date(firstWeeklyDate) //新規
  });
  // 終了日
  const { endDateValue, endDateBOfWValid, endDateBOfWValue } = useDateEnd({
    startDateValue,
    init:
      mode == 'edit'
        ? startOfWeek(new Date(report.endDate), { weekStartsOn: 1 }) // 編集
        : new Date(lastWeeklyDate) // 新規
  });
  // 購買データが空の期間
  const emptyDataValids = ref(false);

  // 出稿金額
  const { advertisingCostValue, advertisingCostValid } = useAdvertisingCost(
    roundNumber(report.advertisingCost)
  );
  // レポートタイトル
  const { reportTitleValue, reportTitleValid } = useTitle({
    report,
    product
  });
  // バリデーション
  const valids = ref({
    reportTitleValue: false,
    endDateBOfWValid: false,
    emptyData: false
  });

  watch(reportTitleValue, () => {
    valids.value.reportTitleValue = !reportTitleValue.value;
  }); // タイトルバリデーション
  watch([startDateValue, endDateBOfWValue], () => {
    valids.value.endDateBOfWValid = endDateValue.value < startDateValue.value;
  }); // 期間バリデーション
  watch(emptyDataValids, v => {
    valids.value.emptyData = v;
    // valids.value.emptyData = v.includes(true);
  }); // 購買データの無い期間バリデーション

  watch(
    valids,
    v => {
      isDisabled.value = Object.values(v).includes(true);
    },
    { deep: true }
  ); //バリデーションまとめ

  // 編集バリデーション
  if (report.title) {
    valids.value.reportTitleValue = false;
  }

  const form: FormValue = {
    areaValue,
    startDateValue,
    endDateValue,
    selectValueReachBasisWeek,
    selectValueReachBasisFrequency,
    advertisingCostValue,
    reportTitleValue
  };
  //
  const { isLoadingCreate, onCreateReport } = useCreateReport();
  const { isLoading, onSave } = useSaveReport();

  return {
    advertisingCostValue,
    advertisingCostValid,
    //
    reportTitleValue,
    reportTitleValid,
    //
    selectValueReachBasisWeek,
    optionsReachBasisWeek,
    //
    selectValueReachBasisFrequency,
    optionsReachBasisFrequency,
    //
    startDateValue,
    startDateValid,
    endDateValue,
    endDateBOfWValid,
    endDateBOfWValue,
    //
    emptyDataValids,
    //
    isLoadingArea,
    areaList,
    areaValue,
    //
    isLoadingCreate,
    onCreateReport,
    isLoading,
    onSave,
    //
    isDisabled,
    form
  };
};
// 期間
const useDateStart = ({ init }): DateStart => {
  const startDateValue = ref(init); //model
  const startDateValid = computed(() => {
    return '';
  }); // valid
  return {
    startDateValue,
    startDateValid
  };
};
// 期間
const useDateEnd = ({ startDateValue, init }): DateEnd => {
  // 終了日週
  const endDateBOfWValue = ref(init);
  // 終了日
  const endDateValue = ref(
    startOfDay(
      endOfWeek(endDateBOfWValue.value, {
        weekStartsOn: 1
      })
    )
  );
  const endDateBOfWValid = computed(() => {
    return startOfDay(endDateValue.value) < startOfDay(startDateValue.value)
      ? '終了日は開始日より後にしてください'
      : '';
  }); // valid
  watch(endDateBOfWValue, () => {
    endDateValue.value = endOfWeek(endDateBOfWValue.value, {
      weekStartsOn: 1
    });
  });
  return {
    endDateValue,
    endDateBOfWValid,
    endDateBOfWValue
  };
};
// リーチ基準
export const useSelectValueReachBasisWeek = ({
  init
}: {
  init: number;
}): SelectValueReachBasisWeek => {
  const selectValueReachBasisWeek = ref({} as Select);
  const optionsReachBasisWeek = ref([] as Select[]);
  optionsReachBasisWeek.value = [...Array(4)].map((_, i) => ({
    label: `${i + 1}週間`,
    value: i + 1
  }));
  selectValueReachBasisWeek.value = optionsReachBasisWeek.value.filter(
    v => v.value === init
  )[0];
  return {
    selectValueReachBasisWeek,
    optionsReachBasisWeek
  };
};
export const useSelectValueReachBasisFrequency = ({
  init
}: {
  init: number;
}): SelectValueReachBasisFrequency => {
  const selectValueReachBasisFrequency = ref({} as Select);
  const optionsReachBasisFrequency = ref([] as Select[]);
  optionsReachBasisFrequency.value = [...Array(10)].map((_, i) => ({
    label: `${i + 1}回`,
    value: i + 1
  }));
  selectValueReachBasisFrequency.value = optionsReachBasisFrequency.value.filter(
    v => v.value === init
  )[0];
  return {
    selectValueReachBasisFrequency,
    optionsReachBasisFrequency
  };
};
// エリア
export const useSimpleArea = ({
  companyId,
  productId,
  init = 'CMSI_ALL'
}: {
  companyId: number;
  productId: number;
  init?: string;
}): Area => {
  const areaList = ref([] as { label: string; value: string }[]);
  const areaValue = ref(init);
  const [isLoadingArea, fetchAreaList] = useLoading(_fetchAreaList);

  (async () => {
    const res = await fetchAreaList({ companyId, productId });
    areaList.value = res.map(v => ({ value: v.areaCode, label: v.areaName }));
    if (res.length > 1) {
      areaList.value.unshift({
        value: 'CMSI_ALL',
        label: '全エリア'
      });
    }
  })();

  return {
    areaList,
    areaValue,
    isLoadingArea
  };
};
const _fetchAreaList = async ({
  companyId,
  productId
}): Promise<CmSalesImpactAvailableArea[]> => {
  try {
    const { data } = await CmsiApi.getCmSalesImpactAvailableAreas(
      companyId,
      productId
    );
    return data;
  } catch (e) {
    if (e.status === httpCode.forbidden || e.name === 'RequiredError')
      return [] as CmSalesImpactAvailableArea[];
    toast({
      title: 'エリア取得の失敗',
      message: e.message,
      variant: 'error'
    });
    return [] as CmSalesImpactAvailableArea[];
  }
};
// 出稿金額
export const useAdvertisingCost = (num = ''): AdvertisingCost => {
  const advertisingCostValue = ref(num);
  const advertisingCostValid = ref();

  return {
    advertisingCostValue,
    advertisingCostValid
  };
};
// タイトル
const useTitle = ({
  report,
  product
}: {
  report: CmSalesImpactReports;
  product: CmSalesImpactProductsDetails;
}): Title => {
  const reportTitleValue = ref();
  // 新規/既存
  if (!report.title) {
    reportTitleValue.value = product.productName;
  } else {
    reportTitleValue.value = report.title;
  }
  // Valid
  const reportTitleValid = computed(() =>
    !reportTitleValue.value ? 'タイトルを入力' : ''
  );

  return {
    reportTitleValue,
    reportTitleValid
  };
};
// 新規作成
const useCreateReport = (): CreateReport => {
  const [isLoading, post] = useLoading(_postCreate);

  return {
    isLoadingCreate: isLoading,
    onCreateReport: post
  };
};
const _postCreate = async ({ form }) => {
  try {
    const { data } = await CmsiApi.postCmSalesImpactReports(form);
    return data;
    // injectのため遷移はvue側で実施
  } catch (e) {
    toast({
      title: 'レポート作成失敗',
      message: e.message,
      variant: 'error'
    });
    return {} as CmSalesImpactReportsPostResponse;
  }
};
// 編集
const useSaveReport = (): Save => {
  const [isLoading, post] = useLoading(_postSave);

  return {
    isLoading: isLoading,
    onSave: post
  };
};
const _postSave = async ({ reportId, form }) => {
  try {
    await CmsiApi.putCmSalesImpactReportsReportId(reportId, form);
    toast({
      title: '設定を変更しました',
      variant: 'success'
    });
  } catch (e) {
    if (e.status === 409) {
      toast({
        title: 'レポート編集出来ません',
        message:
          '集計中のため、レポートの編集・削除ができません。しばらくしてから再度実行してください。',
        variant: 'error'
      });
    } else {
      toast({
        title: 'レポート編集失敗',
        message: e.message,
        variant: 'error'
      });
    }
  }
};
// APIに合わせるformat
export const formatForm = ({
  form,
  productId
}: {
  form: FormValue;
  productId: number;
}): CmSalesImpactReportsForm => {
  // 整形
  const data: CmSalesImpactReportsForm = {
    productId: productId,
    title: form.reportTitleValue.value,
    startDate: format(form.startDateValue.value, 'yyyy-MM-dd'),
    endDate: format(form.endDateValue.value, 'yyyy-MM-dd'),
    reachWeeks: form.selectValueReachBasisWeek.value.value,
    reachFrequency: form.selectValueReachBasisFrequency.value.value,
    areaCode: form.areaValue.value,
    advertisingCost: Number(form.advertisingCostValue.value.replace(/,/g, ''))
  };
  return data;
};
