import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { COMPANY_ROUTES, CAMPAIGN_ROUTES } from '@/router';

import { useStore } from './store';
import { storeToRefs } from 'pinia';

import {
  CmSalesImpactProductsDetails,
  CmSalesImpactReportsDetails,
  CmSalesImpactGraphData
} from '@/api/openapi';

interface Create {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  pageTitle: Ref<string>;
  companyId: Ref<number>;
  graph: Ref<CmSalesImpactGraphData>;
  report: Ref<CmSalesImpactReportsDetails>;
}

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'CMセールスインパクト' },
    {
      name: CAMPAIGN_ROUTES.cmsi,
      label: '売上効果分析',
      query: {
        mode: 'static'
      }
    },
    {
      name: CAMPAIGN_ROUTES.cmsiProduct,
      label: '',
      param: { productId: 0 }
    }
  ],
  current: { label: 'レポートの新規作成' }
};

export const useCreate = ({
  product
}: {
  product: CmSalesImpactProductsDetails;
}): Create => {
  const route = useRoute();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const productId = ref(Number(params.productId));
  const breadcrumbs = ref(BREADCRUMBS);
  const pageTitle = ref('');
  const graph = ref({} as CmSalesImpactGraphData);

  const store = useStore();
  const { fetchStoreGraphs } = store;
  const { report, ...storeRef } = storeToRefs(store);

  (async () => {
    // タイトルの書き換え
    if (product.productName) {
      const name = product.productName;
      const { title } = document;
      document.title = name + ' ' + title;
      // パンくずの書き換え
      breadcrumbs.value.parents.slice(-1)[0].label = name;
      (breadcrumbs.value.parents.slice(-1)[0].param as {
        productId: number;
      }).productId = productId.value;

      pageTitle.value = `${name} レポートの新規作成`;
    }
    //
    graph.value = await fetchStoreGraphs({
      reportId: undefined,
      productId: product.productId,
      companyId: companyId.value
    });
  })();

  return { breadcrumbs, companyId, pageTitle, graph, report, ...storeRef };
};
